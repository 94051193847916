import { Switch , Route } from 'react-router-dom';
import NotFound from '../pages/404';
import SurveyAnswered from '../pages/survey-answered';
import SurveyExpired from '../pages/survey-expired';
import SurveyNotSent from '../pages/survey-not-sent';
import SurveySent from '../pages/survey-sent';
import SurveyNps from '../pages';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SurveyNps} />
      <Route path="/survey-answered" component={SurveyAnswered} />
      <Route path="/survey-expired" component={SurveyExpired} />
      <Route path="/survey-not-sent" component={SurveyNotSent} />
      <Route path="/survey-sent" component={SurveySent} />
      <Route path="/not-exists" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
