import freedom from "../../assets/freedom.svg";
import { ImageContainer } from "./styles";

export default function BackgroundFreedom() {
  return (
    <ImageContainer>
      <img
        src={freedom}
        alt="Liberdade"
        style={{ objectFit: "cover" }}
      />
    </ImageContainer>
  );
}
