import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { GlobalStyle } from './styles/global';
import Routes from './routes';

import { Header, BackgroundFreedom } from './components';

import 'moment/locale/pt-br';

moment.locale('pt-br');

function App() {
  return (
    <BrowserRouter forceRefresh>
      <GlobalStyle />
      <ToastContainer theme="colored" />
      <Header />
      <BackgroundFreedom />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
