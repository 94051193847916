import ReactLoading, { LoadingType } from "react-loading";

interface IPropsSpinner {
  type: LoadingType | undefined;
  color: string;
  height: number;
  width: number;
}

export default function Spinner({ type, color, height, width }: IPropsSpinner) {
  return (
    <ReactLoading
      type={type}
      color={color}
      height={height}
      width={width}
      data-testid="spinner-element"
    />
  );
}
