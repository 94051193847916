import { INoteOptions } from "../@types";

const noteOptions: INoteOptions[] = [
  {
    valueNote: 0,
  },
  {
    valueNote: 1,
  },
  {
    valueNote: 2,
  },
  {
    valueNote: 3,
  },
  {
    valueNote: 4,
  },
  {
    valueNote: 5,
  },
  {
    valueNote: 6,
  },
  {
    valueNote: 7,
  },
  {
    valueNote: 8,
  },
  {
    valueNote: 9,
  },
  {
    valueNote: 10,
  },
];

export { noteOptions };
