import { ComponentPropsWithoutRef, ReactNode } from "react";
import Spinner from "../Spinner";

import { Container } from "./styles";

interface IPropsButton extends ComponentPropsWithoutRef<"button"> {
  loading?: boolean;
  disabled?: boolean;
  children: ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
}

export default function Button({
  loading,
  disabled,
  children,
  type,
  ...rest
}: IPropsButton) {
  return (
    <Container
      type={type}
      disabled={disabled}
      data-testid="button-element"
      {...rest}
    >
      {loading ? (
        <Spinner color="#FFFFFF" height={24} width={24} type="spin" />
      ) : (
        children
      )}
    </Container>
  );
}
