import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Wrapper,
  Content,
  WrapperSurveyNotSent,
  Title,
  SubTitle,
  FooterButton,
  ButtonMore,
} from './styles';

import surveyNotSent from '../../assets/survey-not-sent.svg';

export default function SurveyNotSent() {
  const history = useHistory();

  const handleBackSurvey = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <Container>
      <Wrapper>
        <Content>
          <WrapperSurveyNotSent>
            <img src={surveyNotSent} alt="Pesquisa não respondida" />
          </WrapperSurveyNotSent>
          <Title>Vamos tentar novamente?</Title>
          <SubTitle>Não conseguimos enviar sua resposta</SubTitle>
        </Content>
        <FooterButton>
          <ButtonMore type="button" onClick={handleBackSurvey}>
            Tentar novamente
          </ButtonMore>
        </FooterButton>
      </Wrapper>
    </Container>
  );
}
