import {
  Container,
  Wrapper,
  Content,
  WrapperImageSearchExpires,
  Title,
  SubTitle,
  FooterButton,
  ButtonMore,
} from './styles';

import searchExpires from '../../assets/search-expires.svg';

export default function SurveyExpired() {
  function handleGetViewOffers() {
    window.open(process.env.REACT_APP_URL_HAVAN);
  }
  return (
    <Container>
      <Wrapper>
        <Content>
          <WrapperImageSearchExpires>
            <img src={searchExpires} alt="Pesquisa expirou" />
          </WrapperImageSearchExpires>
          <Title>A pesquisa não está mais disponível</Title>
          <SubTitle>Mas continue com a gente e veja nossas ofertas</SubTitle>
        </Content>
        <FooterButton>
          <ButtonMore
            type="button"
            onClick={handleGetViewOffers}
            data-testid="button-surveyexpired-element"
          >
            Ver ofertas
          </ButtonMore>
        </FooterButton>
      </Wrapper>
    </Container>
  );
}
