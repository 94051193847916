import styled from "styled-components";
import { shade } from "polished";

interface PropsButton {
  disabled?: boolean;
  loading?: boolean;
}

export const Container = styled.button<PropsButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c3bdd;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 7px;
  font-feature-settings: "liga" off;
  line-height: 22px;
  border-radius: 8px;
  border: 0;
  height: 48px;
  width: 100%;
  transition: background 0.2s;
  cursor: ${(props: PropsButton) => (props.loading ? "wait" : "pointer")};

  &:hover {
    background: ${(props: PropsButton) =>
      props.disabled ? "#0c3bdd" : `${shade(0.2, "#06357A")}`};
  }

  &:disabled {
    background: #a0b4d1;
  }
`;
