import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  min-width: 884px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-top: 180px;
  border: 1.5px solid #cee2ff;
  border-radius: 8px;
  padding: 24px;
  z-index: 11;

  @media (max-width: 1024px) {
    min-width: 600px;
  }

  @media (max-width: 620px) {
    min-width: 380px;
  }

  @media (max-width: 508px) {
    margin-top: 40px;
    min-width: 340px;
    background: #f3f8ff;
    justify-content: center;
    align-items: center;
    border: none;
  }

  @media (max-width: 340px) {
    min-width: 300px;
  }
`;

export const WrapperSurveySent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const Title = styled.h2`
  font-size: 1.375rem;
  color: #06357a;
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  width: 100%;
`;

export const SubTitle = styled.h2`
  color: #556f97;
  font-weight: 400;
  font-size: 1.125rem;
  display: inline-block;
  text-align: center;
  width: 100%;

  @media (max-width: 620px) {
    text-align: center;
  }
`;
