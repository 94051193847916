import {
  Container,
  Wrapper,
  Content,
  WrapperImageSearchExpires,
  Title,
  SubTitle,
  FooterButton,
  ButtonMore,
} from './styles';

import searchExpires from '../../assets/search-expires.svg';

export default function NotFound() {
  function handleGetViewOffers() {
    window.open(process.env.REACT_APP_URL_HAVAN);
  }

  return (
    <Container>
      <Wrapper>
        <Content>
          <WrapperImageSearchExpires>
            <img src={searchExpires} alt="Página não existe" />
          </WrapperImageSearchExpires>
          <Title>Ops! Desculpe</Title>
          <SubTitle>
            Não encontramos o que você estava procurando. Por favor, verifique
            os termos digitados e tente outra vez ou veja nossas ofertas.
          </SubTitle>
        </Content>
        <FooterButton>
          <ButtonMore type="button" onClick={handleGetViewOffers}>
            Ver ofertas
          </ButtonMore>
        </FooterButton>
      </Wrapper>
    </Container>
  );
}
