import logo from "../../assets/logo.svg";
import { Container, ImageContainer } from "./styles";

export default function Header() {
  return (
    <Container>
      <ImageContainer>
        <img src={logo} alt="Havan" />
      </ImageContainer>
    </Container>
  );
}
