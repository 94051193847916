import styled from "styled-components";
import { Button } from "../components";

interface PropsButton {
  disabled?: boolean;
  loading?: boolean;
  selectedValue: boolean;
}

interface PropsLimitInput {
  limitInput: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  min-width: 884px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-top: 64px;
  border: 1.5px solid #cee2ff;
  border-radius: 8px;
  padding: 24px;
  z-index: 8;

  @media (max-width: 1024px) {
    min-width: 600px;
  }

  @media (max-width: 620px) {
    min-width: 380px;
  }

  @media (max-width: 770px) {
    margin-top: 40px;
    min-width: 340px;
    background: #f3f8ff;
    border: none;
  }

  @media (max-width: 340px) {
    min-width: 300px;
  }
`;

export const WrapperSurvey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  z-index: 6;
`;

export const Title = styled.h2`
  font-size: 1.375rem;
  color: #06357a;
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  width: 500px;

  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 620px) {
    width: 100%;
  }

  @media (max-width: 770px) {
    width: 100%;
  }

  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const SubTitle = styled.h2`
  color: #556f97;
  font-weight: 400;
  font-size: 1.125rem;
  display: inline-block;
  text-align: center;
  width: 100%;

  @media (max-width: 620px) {
    text-align: center;
  }
`;

export const Body = styled.div`
  margin-top: 24px;
  z-index: 5;
`;

export const Wouldindicate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleIndicate = styled.span`
  font-size: 0.75rem;
  color: #556f97;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
`;

export const FooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;

  @media (max-width: 620px) {
    justify-content: center;
  }

  @media (max-width: 508px) {
    margin-top: 24px;
  }
`;

export const ButtonSend = styled(Button)`
  width: 328px;

  @media (max-width: 620px) {
    bottom: 0px;
    width: 100%;
  }
`;

export const ContentButtonsNps = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 14px;

  @media (max-width: 508px) {
    gap: 2px;
  }
`;

export const ButtonNps = styled.button`
  flex: 1;
  background: ${(props: PropsButton) =>
    props.selectedValue ? "#076BEE" : "#ffffff"};
  color: ${(props: PropsButton) =>
    props.selectedValue ? "#ffffff" : "#06357a"};
  border: 1.5px solid #cee2ff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: "liga" off;
  border-radius: 8px;
  height: 48px;
  transition: background 0.2s;
  cursor: ${(props: PropsButton) => (props.loading ? "wait" : "pointer")};

  &:hover {
    background: ${(props: PropsButton) =>
      props.disabled
        ? "#FFFFFF"
        : "#F1F1F1" && props.selectedValue
        ? "#06357a"
        : "#F1F1F1"};
  }

  &:disabled {
    opacity: 0.6;
  }
`;

export const ContentImprovement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const TitleImprovement = styled.h2`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 25px;
  color: #556f97;
`;

export const InputLines = styled.textarea`
  font-size: 1rem;
  font-weight: 400;
  padding: 16px;
  border-radius: 8px;
  border: ${(props: PropsLimitInput) =>
    props.limitInput ? "2px solid #D0375A" : "1px solid #cee2ff"};
  width: 100%;
  resize: none;

  &:focus {
    outline: none;
    border: ${(props: PropsLimitInput) =>
      props.limitInput ? "2px solid #D0375A" : "2px solid #076BEE"};
  }
`;

export const ContentLines = styled.div`
  display: flex;
  justify-content: ${(props: PropsLimitInput) =>
    props.limitInput ? "space-between" : "flex-end"};
  align-items: center;
  margin-top: 4px;
  resize: none;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${(props: PropsLimitInput) =>
      props.limitInput ? "#D0375A" : "#556f97"};
  }
`;
