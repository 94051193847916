import styled from "styled-components";

export const ImageContainer = styled.div`
  position: absolute;

  @media (min-width: 1680px) {
    left: 0;
    bottom: 0;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;
