import { useEffect } from 'react';

import useIsMounted from '../../hooks/useIsMounted';

import {
  Container,
  Wrapper,
  Content,
  WrapperSurveySent,
  Title,
  SubTitle,
} from './styles';

import surveySent from '../../assets/survey-sent.svg';

export default function SurveySent() {
  const isMounted = useIsMounted();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted() && process.env.REACT_APP_URL_HAVAN) {
        window.location.href = process.env.REACT_APP_URL_HAVAN;
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isMounted]);

  return (
    <Container>
      <Wrapper>
        <Content>
          <WrapperSurveySent>
            <img src={surveySent} alt="Pesquisa enviada" />
          </WrapperSurveySent>
          <Title>Resposta enviada!</Title>
          <SubTitle>
            Sua opinião é muito importante para nós, agradecemos sua
            participação!
          </SubTitle>
        </Content>
      </Wrapper>
    </Container>
  );
}
