import { useEffect } from 'react';

import useIsMounted from '../../hooks/useIsMounted';

import {
  Container,
  Wrapper,
  Content,
  WrapperSurveyAnswered,
  Title,
  SubTitle,
} from './styles';

import surveyAnswered from '../../assets/survey-answered.svg';

export default function SurveyAnswered() {
  const isMounted = useIsMounted();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted() && process.env.REACT_APP_URL_HAVAN) {
        window.location.href = process.env.REACT_APP_URL_HAVAN;
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isMounted]);

  return (
    <Container>
      <Wrapper>
        <Content>
          <WrapperSurveyAnswered>
            <img src={surveyAnswered} alt="Pesquisa respondida" />
          </WrapperSurveyAnswered>
          <Title>Você já respondeu a essa pesquisa!</Title>
          <SubTitle>
            Não será possível responde-la novamente, mas agradecemos a sua
            participação
          </SubTitle>
        </Content>
      </Wrapper>
    </Container>
  );
}
